import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Collections from '../../components/Collections'

const CollectionsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  return (
      <Layout>
        <Helmet title={`Collections | ${title}`}/>
        <>
          <div className="has-text-centered is-size-2 pt-6 pb-4 color-purple">Olena Isai</div>
          <div className="content is-vcentered has-text-centered py-6 my-6">
            <Collections collections={group} />
          </div>
        </>
      </Layout>
  )
}

export default CollectionsPage

export const collectionPageQuery = graphql`
  query CollectionsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___collections) {
        fieldValue
        totalCount
      }
    }
  }
`
